
import { Options, Vue } from 'vue-class-component';
import { CheckOne, CloseOne } from '@icon-park/vue-next';

@Options({
    components: {
        CheckOne, CloseOne
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        toTitle: {
            type: String,
            default: ''
        },
        duration: {
            type: Number,
            default: 5
        },
        backText: {
            type: String,
            default: '返回列表'
        },
        nextText: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: 'SUCCESS'
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            second: 5
        }
    },
    watch: {
        show(nVal){
            if(nVal) this.countDown();
        }
    },
    emits: ['go-next', 'go-back', 'update:show'],
    mounted() {
        this.second = this.duration;
        this.countDown();
    },
    methods: {
        countDown(){
            if(this.duration !== 0 && this.show){
                if(this.second > 0) {
                    this.second -= 1;
                    setTimeout(() => {
                        this.countDown();
                    }, 1000)
                } else this.$emit('go-back');
            }
        },
        handlerBack(){
            this.$emit('go-back');
            this.$emit('update:show', false);
        },
        handlerNext(){
            this.$emit('go-next');
            this.$emit('update:show', false);
        }
    }
})

export default class OperationResult extends Vue {}
