<template>
    <div class="operation-result" v-if="show">
        <check-one v-if="status === 'SUCCESS'" theme="filled" size="54" fill="#00c12b" />
        <close-one v-else theme="filled" size="54" fill="#ff2121" />
        <h2 class="title">{{ title }}</h2>
        <p class="text" v-if="duration !== 0">{{ second }}s后自动跳转至{{ toTitle }}</p>
        <div class="btn-group">
            <n-button class="btn" type="primary" @click="handlerBack">{{ backText }}</n-button>
            <n-button v-if="nextText !== ''" class="btn" @click="handlerNext">{{ nextText }}</n-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CheckOne, CloseOne } from '@icon-park/vue-next';

@Options({
    components: {
        CheckOne, CloseOne
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        toTitle: {
            type: String,
            default: ''
        },
        duration: {
            type: Number,
            default: 5
        },
        backText: {
            type: String,
            default: '返回列表'
        },
        nextText: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: 'SUCCESS'
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            second: 5
        }
    },
    watch: {
        show(nVal){
            if(nVal) this.countDown();
        }
    },
    emits: ['go-next', 'go-back', 'update:show'],
    mounted() {
        this.second = this.duration;
        this.countDown();
    },
    methods: {
        countDown(){
            if(this.duration !== 0 && this.show){
                if(this.second > 0) {
                    this.second -= 1;
                    setTimeout(() => {
                        this.countDown();
                    }, 1000)
                } else this.$emit('go-back');
            }
        },
        handlerBack(){
            this.$emit('go-back');
            this.$emit('update:show', false);
        },
        handlerNext(){
            this.$emit('go-next');
            this.$emit('update:show', false);
        }
    }
})

export default class OperationResult extends Vue {}
</script>

<style lang="scss" scoped>
.operation-result{
    @extend .flex-column-center;
    margin: 10px;
    padding: 50px 0;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
    .title{
        margin: 10px 0 10px;
        font-size: 28px;
        font-weight: normal;
    }
    .text{
        margin: 0 0 10px;
        color: $grey;
    }
    .btn-group{
        @extend .flex-row;
        .btn{
            margin: 0 10px;
        }
    }
}
</style>
